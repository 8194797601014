import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Patient} from '../../../entities/Patient';
import {AbstractDataTableComponent} from '../../generics/AbstractDataTableComponent';
import {MeasurementStub} from '../../../entities/MeasurementStub';
import {MeasurementService} from '../../../services/measurement.service';
import {Subject, Subscription} from 'rxjs';
import {saveAs} from 'file-saver';
import * as moment from 'moment';
import { AnamnesenService } from 'src/app/services/anamnesen.service';
import { AnamnesenStub } from 'src/app/entities/AnamnesenStub';

declare var jQuery: any;

@Component({
  selector: 'app-patient-detail-anamnesen',
  templateUrl: './patient-detail-anamnesen.component.html',
  styleUrls: ['./patient-detail-anamnesen.component.scss']
})
export class PatientDetailAnamnesenComponent extends AbstractDataTableComponent implements OnInit, OnDestroy {

  @Input() patient: Patient;
  anamnesenStubs: Array<AnamnesenStub> = [];
  subs: Array<Subscription> = [];

  constructor(private anamnesenService: AnamnesenService) {
    super('patient-anamnesen-list');
  }

  ngOnInit() {

    // input format is 'DD.MM.YYYY HH:mm' from BackEnd
    // DateTables uses Date.parse(xx) for sorting
    // Date.parse(xx) cant parse this specific format, thus it will fall back to string sorting
    // to correctly sort date-de format, we must parse date string via moment manually
    // see: https://stackoverflow.com/a/55000726
    this.dtOptions.columnDefs = [
        {
          targets: 0,
           type: 'date-time',
           render: function(data, type) {
             // console.log('render colDef', data, type);
             return type === 'sort' ? moment(data, 'DD.MM.YYYY HH:mm').format('YYYY/MM/DD HH:mm') : data;
           }
        }
    ];
    
    this.getAnamnesen();
  }

  getAnamnesen(){
    const sub = this.anamnesenService.getList(this.patient.id)
            .subscribe(list => {
              console.log('stubs', list);
              this.anamnesenStubs = list;
              this.rerender();
            });
    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
  }

  getCsv(id, measurementDate, patientId, mandantId): void {
    this.anamnesenService
        .getCsv(patientId, id)
        .subscribe((data: any) => {
          const blob = new Blob([data], { type: 'application/octet-stream' });
          const fileName = mandantId + '_' + patientId + '_' + measurementDate + '_raw.csv';
          saveAs(blob, fileName);
        });
  }

  openFormServerUrl(url: string) {
    window.open(url, "_blank");
  }

}
