import { Component } from '@angular/core';
import { UsbService } from 'src/app/services/usb.service';
import { FeatureToggleService } from 'src/app/services/feature_toggles_service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent {
  // used as variables in the html file
  usbStatusClass: string = 'status-indicator red';
  usbStatusHint: string = 'USB Service nicht verfügbar!';
  usbServiceVersion: string = 'n.v.';
  footerEnabled: boolean = false;
  usbStickReady: boolean = false;
  usbServiceReady: boolean = false;
  totalMeasurementsCount: number;
  uploadedMeasurementsCount: number;

  // internal variables
  featureToggleServiceSub: Subscription;
  usbServiceSub: Subscription;
 
  constructor(
    private usbService: UsbService,
    private featureToggleService: FeatureToggleService,
  ) { }

  ngOnInit() {
    this.featureToggleServiceSub = this.featureToggleService.getFeatureStatus('new_usb_service')
      .subscribe((status) => {
        this.footerEnabled = status;
        if (status) {
          this.activateUsbService();
        }
      })
  }

  activateUsbService() {
    console.log('Activate USB service');
    this.usbService.startUsbService();
    const repeatInterval = window.location.hostname === '127.0.0.1' ? 10000 : 5000;

    this.usbServiceSub = this.usbService.currentEvent.subscribe((status) => {
      this.usbServiceVersion = status.version;
      this.usbServiceReady = true;
      if (status.status === 'USB_SERVICE_NOT_AVAILABLE') {
        this.usbStatusClass = 'status-indicator red';
        this.usbStatusHint = 'USB Service nicht verfügbar!';
        this.usbStickReady = false;
      } else if (status.error.length > 0) {
        this.usbStatusClass = 'status-indicator yellow';
        this.usbStatusHint = status.error;
        this.usbStickReady = false;
      } else {
        this.usbStatusHint = status.usb_path;
        this.usbStatusClass = 'status-indicator green';
        this.usbStickReady = true;
      }
    });

    this.usbService.currentUsbStickCounts.subscribe(newUsbCount => {
      console.log('new USB count status:', newUsbCount);
      if (newUsbCount) {
        this.totalMeasurementsCount = newUsbCount.totalCount;
        this.uploadedMeasurementsCount = newUsbCount.uploadedCount;
      }
    });
  }


  ngOnDestroy() {
    if (this.featureToggleServiceSub){
      this.featureToggleServiceSub.unsubscribe();
    }
    if (this.usbServiceSub){
      this.usbServiceSub.unsubscribe();
    }
  }


}