import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractDataTableComponent} from '../../generics/AbstractDataTableComponent';
import {OperatorService} from '../../../services/operator.service';
import {Subscription} from 'rxjs';
import {OperatorView} from '../../../entities/OperatorView';

@Component({
  selector: 'app-operator-list',
  templateUrl: './operator-list.component.html',
  styleUrls: ['./operator-list.component.scss']
})
export class OperatorListComponent extends AbstractDataTableComponent implements OnInit, OnDestroy {

    subs: Subscription[] = [];
    operators: OperatorView[] = [];

    constructor(private operatorService: OperatorService) {
      super('operator list');
    }

    ngOnInit() {
        this.subs.push(this.operatorService.getList().subscribe(list => {
            this.operators = list;
            this.rerender();
        }));

        this.dtOptions.order = [
            [0, 'asc'], // nach ID ordnen als default
        ];
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
        this.subs = [];
    }

}
