import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureToggle } from '../entities/FeatureToggle';


@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {

  featureStatus: Map<string, boolean> = new Map<string, boolean>();

  constructor(
      private http: HttpClient,
  ) {
  }

  loadFeatureStatus(featureName: string): Observable<boolean> {
    return this.getFeaturesMinimal().map((data) => {
      this.featureStatus = new Map<string, boolean>(Object.entries(data));
      console.log('Feature toggles loaded');
      let t = this.featureStatus.get(featureName)
      return t;
    });
  }

  public getFeatureStatus(featureName: string): Observable<boolean> {
    if(this.featureStatus.size == 0) {
      return this.loadFeatureStatus(featureName);
    }
    return new Observable((subscriber) => {
      subscriber.next(this.featureStatus.get(featureName));
      subscriber.complete();
    });
  }


  // Get all feature toggles only with their names and enabled status
  public getFeaturesMinimal(): Observable<Map<string, boolean>> {
    return this.http.get<Map<string, boolean>>('/api/feature_toggles/minimal?t=' + new Date().getTime());
  }

  // Get all feature toggles as a list
  public getFeatures(): Observable<Array<FeatureToggle>> {
    return this.http.get<Array<FeatureToggle>>('/api/feature_toggles');
  }

  public setFeatureStatus(featureId: Number, enabled: boolean): Observable<FeatureToggle> {
    return this.http.put<FeatureToggle>('/api/feature_toggles/' + featureId, {enabled: enabled});
  }
}
