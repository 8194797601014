import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Subscription} from 'rxjs';
import {CurrentUser} from '../../entities/CurrentUser';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  subs: Array<Subscription> = [];
  currentUser: CurrentUser;

  constructor(
      private authService: AuthService
  ) { }

  ngOnInit() {
    this.subs.push(this.authService.currentUser$.subscribe(user => this.currentUser = user));
  }

  ngOnDestroy(): void {
    if (this.subs != null) { this.subs.forEach(sub => sub.unsubscribe()); }
    this.subs = [];
  }
}
