import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {ComplexPartName, ImgParam, MeasurementService, SideName} from '../../../services/measurement.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {MeasurementDetailsSummary} from '../../../entities/MeasurementDetailsSummary';

@Component({
  selector: 'app-display-images-one-side',
  templateUrl: './display-images-one-side.component.html',
  styleUrls: ['./display-images-one-side.component.scss']
})
export class DisplayImagesOneSideComponent implements OnInit, OnChanges, OnDestroy {

    @Input() measurementId: string;

    @Input() side: string;

    // Farbabgleich zur anderen Seite - wird vom Server ausgewertet beim erstellen der Bilder
    @Input() colorBalancing: boolean;

    @Input() fixed_frequency: number; // if set, user can't slide throught frequencies

    @Input() measurementDetails: MeasurementDetailsSummary;

    @Output() frequencyChanged: EventEmitter<Number> = new EventEmitter<Number>();

    label = ''; // string

    current_freq: number = null; // current slider position (frequency value)

    currently_loading = false; // block slider while loading

    img_real: any;
    img_imag: any;
    api_sidename: SideName;
    subs: Array<Subscription> = [];
    min_freq: number;
    max_freq: number;

    constructor(
        private measurementService: MeasurementService,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        switch (this.side) {
            case 'RECHTS':
                this.label = 'Rechts';
                this.api_sidename = SideName.RIGHT;
                break;
            case 'LINKS':
                this.label = 'Links';
                this.api_sidename = SideName.LEFT;
                break;
            default:
                // side must be 'RECHTS' or 'LINKS' onInit
                throw new Error('side must be constant "RECHTS" or "LINKS"');
        }

        if ('measurementDetails' in changes) {
            console.log('---- measurement details changed');
        }

        if ('colorBalancing' in changes && this.colorBalancing != null) {
            console.log('---- color balancing changed', this.colorBalancing);
        }

        if ('fixed_frequency' in changes && this.fixed_frequency != null) {
            console.log('---- fixed frequency was set', this.fixed_frequency);
        }

        if (this.fixed_frequency != null) {
            this.loadAndAssignImages(this.fixed_frequency);
        } else if ((this.current_freq == null) && ('measurementDetails' in changes)) {
            if (this.measurementDetails != null) {
                // image details change only once, thus reset to lowest frequency
                const initial_frequency = this.measurementDetails.frequencies.min;
                this.min_freq = this.measurementDetails.frequencies.min;
                this.max_freq = this.measurementDetails.frequencies.max;
                this.loadAndAssignImages(initial_frequency);
            }
        } else if ((this.current_freq != null) && ('colorBalancing' in changes)) {
            this.loadAndAssignImages(this.current_freq);
        }
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    /**
     * reload image, if slider moved
     */
    public slide(val: number) {
        console.log('slide (new/old)', val, this.current_freq);
        if (this.current_freq == null) {
            return;
        } // exit, if no frequency given
        if (this.currently_loading) {
            return;
        } // exit, if currently loading an image
        const old_freq = this.getClosestFrequency(this.current_freq);
        const new_freq = this.getClosestFrequency(val);

        if (old_freq !== new_freq) {
            this.loadAndAssignImages(new_freq);
        }
    }

    /**
     * load images for real and imaginary part depeding on current_freq and injected side
     *
     * setzt nach erfolgreichem laden die aktuelle Frequenz und gibt den slider wieder frei
     */
    public loadAndAssignImages(freq: number) {
        if (typeof this.api_sidename === 'undefined') {
            console.log('skip loading, since side is undefined');
            return;
        }

        console.log('reload image, freq val has changed');

        // block slider while loading
        this.currently_loading = true;

        // set current frequency as being loaded from server
        this.current_freq = freq;

        // and inform external world about changed frequency
        this.frequencyChanged.emit(freq);

        // load real part
        const imgParamReal: ImgParam = {
            freq: freq,
            side: this.api_sidename,
            complex_part: ComplexPartName.REAL,
            color_balancing: this.colorBalancing
        };
        const realResponse = this.measurementService.getImage(this.measurementId, imgParamReal);

        // load imag part
        const imgParamImag: ImgParam = {
            freq: freq,
            side: this.api_sidename,
            complex_part: ComplexPartName.IMAG,
            color_balancing: this.colorBalancing
        };
        const imagResponse = this.measurementService.getImage(this.measurementId, imgParamImag);

        // assign both responses to images properties and release slider

        forkJoin([realResponse, imagResponse])
            .subscribe(responseList => {
                this.img_real = this.sanitizer.bypassSecurityTrustUrl(
                    window.URL.createObjectURL(responseList[0])
                );

                this.img_imag = this.sanitizer.bypassSecurityTrustUrl(
                    window.URL.createObjectURL(responseList[1])
                );

                // release slider
                this.currently_loading = false;
            });
    }

    /**
     * berechnet die nächstgelegene Frequenz in der Liste möglicher Frequenzen zu einem belibiegen Eingangswert
     * die Liste der möglichen Frequenzen wir zu Beginn geladen mit den detailsSummary
     */
    private getClosestFrequency(freq: number): number {
        return this.measurementDetails.frequencies.list.reduce((min, curr) => {
                const curr_dist = Math.abs(curr - freq);
                const old_dist = Math.abs(min - freq);
                return old_dist > curr_dist ? curr : min;
            }, this.measurementDetails.frequencies.max
        );
    }
}
