import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractDataTableComponent} from '../../generics/AbstractDataTableComponent';
import {Subscription} from 'rxjs';
import {UserService} from '../../../services/user.service';
import {ManagableUser} from '../../../entities/ManagableUser';
import {AuthService} from '../../../services/auth.service';
import {CurrentUser} from '../../../entities/CurrentUser';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent extends AbstractDataTableComponent implements OnInit, OnDestroy {

  users: Array<ManagableUser> = [];
  subs: Array<Subscription> = [];
  currentUser: CurrentUser = null;

  constructor(
      private userService: UserService,
      private authService: AuthService) {
    super('users-list');
  }

  ngOnInit() {
    const sub: Subscription = this.userService.getMyUsers()
        .subscribe(arr => {
          this.users = arr;
          this.rerender();
          console.log('received users', arr);
        });
    this.subs.push(sub);

    this.currentUser = this.authService.getCurrentUser();
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
  }
}
