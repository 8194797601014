import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Subscription} from 'rxjs';
import {CurrentUser} from '../entities/CurrentUser';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit, OnDestroy {

  // the input accept a single roles name
  @Input('appHasRole') private role: string;

  protected subscription: Subscription;

  constructor(
      private templateRef: TemplateRef<any>,
      private viewContainer: ViewContainerRef,
      private authService: AuthService
  ) { }

  public static hasRole(user: CurrentUser, required_role: string): boolean {
    return (user != null) && (user.roles != null) && (user.roles.includes(required_role));
  }

  ngOnInit(): void {
    // check view role privilege
    this.subscription = this.authService.currentUser$
        .subscribe((user) => {
          this.viewContainer.clear()
          if (user != null && user.hasRole(this.role)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          }
        });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
