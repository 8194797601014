import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {MeasurementStub} from '../entities/MeasurementStub';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {MeasurementDetailsSummary} from '../entities/MeasurementDetailsSummary';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MeasurementService {

  constructor(private httpClient: HttpClient) { }

  /**
   * a non cached list of MeasurementStubs for Patient
   */
  public getList(patientId: number): Observable<Array<MeasurementStub>> {
      return this.httpClient.get('/api/patients/'+ patientId + '/measurements')
          .map((resp: Array<any>) => {
            const mlist: Array<MeasurementStub> = [];
            resp.forEach(item => mlist.push(MeasurementStub.parse(item))); // Object.assign(new MeasurementStub(), item)));
            return mlist;
          });
  }

  public getMeasurementDetails(measurementId: string): Observable<MeasurementDetailsSummary> {
      return this.httpClient.get('/api/measurements/details/' + measurementId)
          .map(resp => {
              return Object.assign(new MeasurementDetailsSummary(), resp);
          });
  }

    public getMeasurementDetailsCsv(measurementId: string): Observable<ArrayBuffer> {
        const options: {
            headers?: HttpHeaders;
            observe?: 'body';
            params?: HttpParams;
            reportProgress?: boolean;
            responseType: 'arraybuffer';
            withCredentials?: boolean;
        } = {
            responseType: 'arraybuffer'
        };
        return this.httpClient.get('/api/measurements/details/' + measurementId + '/csv', options)
            .pipe(
                map((file: ArrayBuffer) => {
                    return file;
                })
            );
    }

  public getImage(measurementId: string, imgParam: ImgParam): Observable<Blob> {
      const url = '/api/measurements/details/' + measurementId + '/images' +
          '?side=' + imgParam.side.toLocaleString() +
          '&cpart=' + imgParam.complex_part.toLocaleString() +
          '&frequency=' + imgParam.freq +
          '&balance-colors=' + imgParam.color_balancing;
      return this.httpClient.get(url, {responseType: 'blob'});
  }


  /**
   * Check a list of measuremet identifiers if measurements exists
   * @param ids 
   * @returns Array of measurement identifiers that not in the database
   */
  public checkMeasurementIdentifiers(ids: Array<number>): Observable<Array<number>> {
    const url = '/api/measurements/check_exists';
    //const params = new HttpParams().set('ids', ids.join(','));
    return this.httpClient.post<Array<number>>(url, ids);
  }

  public uploadMeasurement(data: string): Observable<any> {
    const url = '/api/measurements/upload_json';
    console.log('Uploading measurements data to ' + url);
    return this.httpClient.post(url, data);
  }
}

// naming convention for API
export interface ImgParam {
    freq: number;
    side: SideName;
    complex_part: ComplexPartName;
    color_balancing: boolean;
}

export enum SideName {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT'
}

export enum ComplexPartName {
    REAL = 'REALTEIL',
    IMAG = 'IMAGINAERTEIL'
}


