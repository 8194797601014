import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MeasurementService} from '../../../services/measurement.service';
import {Subscription} from 'rxjs';
import 'rxjs-compat/add/observable/of';
import {MeasurementDetailsSummary} from '../../../entities/MeasurementDetailsSummary';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-measurement-detail-range',
  templateUrl: './measurement-detail-range.component.html',
  styleUrls: ['./measurement-detail-range.component.scss']
})
export class MeasurementDetailRangeComponent implements OnInit, OnChanges, OnDestroy {
    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
    }
}
    /*
    @Input() image: string; // nullable ! convention, values being LEFT_REAL, LEFT_IMAG, RIGHT_REAL, RIGHT_IMAG

    measurementId: string; // TODO: should be injected by input for save encapsulation

    headline = null;
    detailsSummary: MeasurementDetailsSummary = null;
    current_freq: number = null; // current slider position (frequency value)
    img: any;
    currently_loading = false;

    subs: Array<Subscription> = [];

    constructor(
        private route: ActivatedRoute,
        private measurementService: MeasurementService,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit() {
        // extract measurement ID from route ..
        // TODO this is a bit hacky, since we have no guarantee that component is placed in such a route
        const sub: Subscription = this.route.params
            .switchMap(params => {
                const id: string = params['id']; // measurement identifier is a string !
                this.measurementId = id;
                return this.measurementService.getMeasurementDetails(id);
            })
            .subscribe(summary => {
                this.detailsSummary = summary;
                // do not set current frequency here, since we do not have an image yet
            });
        this.subs.push(sub);
    }

    // input changes, i.e. select another imager
    ngOnChanges() {
        console.log('chosen image', this.image);
        // this.current_freq = null; .. if we set null here, the slider disappears until ne image is loaded .. if not nulled, it simply

        const imgParam = this.mapToImgParam();
        if (imgParam != null && this.detailsSummary != null) {
            const initial_frequency = this.detailsSummary.frequencies.min; // image changed, thus reset to lowest frequency
            this.loadImage(initial_frequency, imgParam);
            this.headline = imgParam.headline;
        } else {
            this.img = null;
            this.headline = null;
        }
    }

    /**
     * reload image, if slider moved
     *
     * @param val
     */
    /*
    public slide(val: number) {
        console.log('slide', val, this.currently_loading, this.current_freq);
        if (this.current_freq == null) { return; } // exit, if no frequency given
        if (this.currently_loading) {return; } // exit, if currently loading an image
        const old_val = this.getClosestFrequency(this.current_freq);
        const new_val = this.getClosestFrequency(val);

        if (old_val !== new_val) {
            console.log('reload image, val has changed');
            const imgParam = this.mapToImgParam();
            this.loadImage(new_val, imgParam);
            this.headline = imgParam.headline;
        }
    }

    /**
     * berechnet die nächstgelegene Frequenz in der Liste möglicher Frequenzen zu einem belibiegen Eingangswert
     * die Liste der möglichen Frequenzen wir zu Beginn geladen mit den detailsSummary
     */
    /*
    protected getClosestFrequency(freq: number): number {
        return this.detailsSummary.frequencies.list.reduce((min, curr) => {
                const curr_dist = Math.abs(curr - freq);
                const old_dist = Math.abs(min - freq);
                return old_dist > curr_dist ? curr : min;
            }, this.detailsSummary.frequencies.max
        );
    }

    /**
     * read input image and extract properties like side, complex part and headline
     * returns null, if no image chosen
     */

    /*
    mapToImgParam(): ImgParam {
        if (typeof this.image === 'undefined' || this.image == null) { return null; }

        let img_param: ImgParam;
        switch (this.image) {
            case 'LEFT_REAL':
                img_param = {side: 'LEFT', complex_part: 'REALTEIL', headline: 'Real Links'};
                break;
            case 'LEFT_IMAG':
                img_param = {side: 'LEFT', complex_part: 'IMAGINAERTEIL', headline: 'Imaginär Links'};
                break;
            case 'RIGHT_REAL':
                img_param = {side: 'RIGHT', complex_part: 'REALTEIL', headline: 'Real Rechts'};
                break;
            case 'RIGHT_IMAG':
                img_param = {side: 'RIGHT', complex_part: 'IMAGINAERTEIL', headline: 'Imaginär Rechts'};
                break;
            default:
                img_param = null;
        }
        return img_param;
    }

    loadImage(new_frequency: number, imgParam: ImgParam) {
        this.currently_loading = true;
        const sub: Subscription = this.measurementService
            .getImage(this.measurementId, new_frequency, imgParam.side, imgParam.complex_part)
            .subscribe(blob => {
                this.img = this.sanitizer.bypassSecurityTrustUrl(
                    window.URL.createObjectURL(blob)
                );
                // set current frequency as loaded from server
                this.current_freq = new_frequency;
                // release slider
                this.currently_loading = false;
            });
        this.subs.push(sub);
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
        this.subs = [];
    }
}

interface ImgParam {
    side: string;
    complex_part: string;
    headline: string;
}*/

