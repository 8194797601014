import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {AuthGuard} from './guards/AuthGuard';
import {PatientListComponent} from './components/patient/patient-list/patient-list.component';
import {PatientDetailMenueComponent} from './components/patient/patient-detail-menue/patient-detail-menue.component';
import {MeasurementComponent} from './components/measurement/measurement.component';
import {ValidationComponent} from './components/measurement/validation/validation.component';
import {MeasurementDetailRangeComponent} from './components/measurement/measurement-detail-range/measurement-detail-range.component';
import {MandantListComponent} from './components/mandanten/mandant-list/mandant-list.component';
import {MandantDetailComponent} from './components/mandanten/mandant-detail/mandant-detail.component';
import {UserListComponent} from './components/users/user-list/user-list.component';
import {UserDetailComponent} from './components/users/user-detail/user-detail.component';
import {PatientEditComponent} from './components/patient/patient-edit/patient-edit.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {OperatorListComponent} from './components/operators/operator-list/operator-list.component';
import {OperatorDetailComponent} from './components/operators/operator-detail/operator-detail.component';
import { FeatureComponent } from './components/feature/feature.component';
import { ConfigComponent } from './components/config-param/config-param.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  { path: 'login', component: LoginComponent},
  { path: 'patienten', component: PatientListComponent, canActivate: [AuthGuard]},
  { path: 'patienten/:pid', component: PatientDetailMenueComponent, canActivate: [AuthGuard]},
  { path: 'patient-edit/:pid', component: PatientEditComponent, canActivate: [AuthGuard]},
  { path: 'measurement/:id', component: MeasurementComponent, canActivate: [AuthGuard]},
  { path: 'measurement-validation/:id', component: ValidationComponent, canActivate: [AuthGuard]},
  { path: 'measurement-detail-range/:id', component: MeasurementDetailRangeComponent, canActivate: [AuthGuard]},
  { path: 'mandanten', component: MandantListComponent, canActivate: [AuthGuard]},
  { path: 'mandanten/:id', component: MandantDetailComponent, canActivate: [AuthGuard]},
  { path: 'users', component: UserListComponent, canActivate: [AuthGuard]},
  { path: 'users/:id', component: UserDetailComponent, canActivate: [AuthGuard]},
  { path: 'operators', component: OperatorListComponent, canActivate: [AuthGuard]},
  { path: 'operators/:id', component: OperatorDetailComponent, canActivate: [AuthGuard]},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  { path: 'feature', component: FeatureComponent, canActivate: [AuthGuard]},
  { path: 'config', component: ConfigComponent, canActivate: [AuthGuard]}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
