import { Injectable } from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {Role} from '../entities/Role';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(
      private http: HttpClient,
      private authService: AuthService
  ) {
  }

  public getAssignableRoles(): Observable<Array<String>> {
    return this.http.get<Array<String>>('/api/users/assignable-roles');
  }
}
