import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {MeasurementStub} from '../entities/MeasurementStub';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {MeasurementDetailsSummary} from '../entities/MeasurementDetailsSummary';
import {map} from 'rxjs/operators';
import { AnamnesenStub } from '../entities/AnamnesenStub';
import { DiagnosenStub } from '../entities/DiagnosenStub';

@Injectable({
  providedIn: 'root'
})

/**
 * Service für die manuellen Diagnosen (Diagnosen-Formular erfasst vom Arzt)
 */
export class DiagnosenService {

  constructor(private httpClient: HttpClient) { }

  /**
   * a non cached list of DiagnosenStub for Patient
   */
  public getList(patientId: number): Observable<Array<AnamnesenStub>> {
      return this.httpClient.get('/api/patients/' + patientId + '/diagnosen')
          .map((resp: Array<any>) => {
            const mlist: Array<DiagnosenStub> = [];
            resp.forEach(item => mlist.push(DiagnosenStub.parse(item))); 
            return mlist;
          });
  }

    public getCsv(id: string, patientId: string): Observable<ArrayBuffer> {
        const options: {
            headers?: HttpHeaders;
            observe?: 'body';
            params?: HttpParams;
            reportProgress?: boolean;
            responseType: 'arraybuffer';
            withCredentials?: boolean;
        } = {
            responseType: 'arraybuffer'
        };
        return this.httpClient.get('/api/patients/'+ patientId +'/diagnosen/' + id +'/csv', options)
            .pipe(
                map((file: ArrayBuffer) => {
                    return file;
                })
            );
    }
}
