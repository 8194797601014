import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Event } from '../entities/Event'
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {NotificationMessage} from '../entities/NotificationMessage';
import {NotificationSeverityEnum} from '../enums/NotificationSeverityEnum';

/**
 * The NotificationSErvice is a message queue for notifications.
 * Notifications are
 */
@Injectable()
export class NotificationService {

  private static MESSAGE_TIMEOUT_ERROR = 12000; // in milliseconds
  private static MESSAGE_TIMEOUT_WARN = 8000; // in milliseconds
  private static MESSAGE_TIMEOUT_OK = 3000; // in milliseconds
  private static MESSAGE_TIMEOUT_INFO = 4000; // in milliseconds

  private messages: Array<NotificationMessage> = [];

  // can be subscribed by observers
  public messages$: ReplaySubject<Array<NotificationMessage>> = new ReplaySubject(1);

  constructor(private httpClient: HttpClient) {}

  getBackEndMessages(): Observable<Event>{
    return this.httpClient
      .get<Event>('/api/ui_events');
  }

  readMessage(eventId: number){
    const dto = {
      id: eventId
    }
    return this.httpClient
      .put('/api/ui_events/'+ eventId, {});
  }

  success(detail: string, summary?: string): void {
    this.createMessage(NotificationSeverityEnum.SUCCESS, NotificationService.MESSAGE_TIMEOUT_OK, detail, summary);
  }

  warn(detail: string, summary?: string): void {
    this.createMessage(NotificationSeverityEnum.WARN, NotificationService.MESSAGE_TIMEOUT_WARN, detail, summary);
  }

  info(detail: string, summary?: string): void {
    this.createMessage(NotificationSeverityEnum.INFO, NotificationService.MESSAGE_TIMEOUT_INFO, detail, summary);
  }

  error(detail: string, summary?: string): void {
    this.createMessage(NotificationSeverityEnum.ERROR, NotificationService.MESSAGE_TIMEOUT_ERROR, detail, summary);
  }

  private createMessage(severity: NotificationSeverityEnum, timeout: number, detail: string, summary?: string, ) {
    const id = this.getAutoIncrement();
    // createMessage
    this.messages.push({severity, id, detail, summary});
    // inform others
    this.messages$.next(this.messages);

    // dispose automativally
    setTimeout(() => {
      this.disposeMessage(id);
    }, timeout);
  }

  public disposeMessage(id: number) {
    const indx: number = this.messages.findIndex(m => m.id === id);
    const disposedItems: NotificationMessage[] = this.messages.splice(indx, 1); // remove this element from array
    this.messages$.next(this.messages);
  }

  /**
   * loops through messages and returns the largest id + 1
   */
  private getAutoIncrement(): number {
    if (this.messages.length === 0) { return 1; }
    return Math.max.apply(null, this.messages.map(m => m.id)) + 1;
  }
}
