import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
  

/**
 * Service für die aus den Messergebnissen generierten Diagnosen
 */
export class DiagnosisService {
  private apiUrl = '/api/diagnosis';

  constructor(private httpClient: HttpClient) { }

  /**
   * get all diagnoses results as json
   */
  public getAll(): Observable<any> {
      return this.httpClient.get(this.apiUrl, {responseType: 'arraybuffer' as 'json' });
  }
}
