import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {ManagableUser} from '../entities/ManagableUser';
import {SaveUserDto} from '../entities/SaveUserDto';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient) { }

    // returns all users that the currently loggedIn user can manage (depending on role and associated mandants)
    public getMyUsers(): Observable<Array<ManagableUser>> {
        return this.http.get('/api/users')
            .map((resp: Array<any>) => {
                const result: Array<ManagableUser> = [];
                resp.forEach( item => result.push(Object.assign(new ManagableUser(), item)));
                return result;
            });
    }

    saveUser(user: SaveUserDto): Observable<any> {
        return this.http.put('/api/users', JSON.stringify(user));
    }
}
