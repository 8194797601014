import * as moment from 'moment';

export class Patient {
  id: number; // internal id, to update patient
  pid: number; // patient id, manually set and unique by mandant
  name: string;
  measurement_count: number;
  last_measurement: Date;
  mandant: string; // mandant name
  mandant_id: number; // mandant id

  public static parse(rawData: any): Patient {
    const result: Patient = Object.assign(new Patient(), rawData);
    console.log('patient parsed', result);
    result.last_measurement = 'last_measurement' in rawData
        ? moment(rawData['last_measurement'], 'DD.MM.YYYY HH:mm').toDate()
        : null;
    return result;
  }
}


