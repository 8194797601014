import {Mandant} from './Mandant';

// dto as received from backend

export class ManagableUser {
    id: number;
    username: string;
    enabled: boolean;
    mandant: Mandant; // for received user
    roles: any; // received list of objects, send a list of strings (for save)
    // email: string;
    password?: string;

    roleSummary(): string {
        return this.roles.map(role => String(role).toLocaleLowerCase()).join(';');
    }
}
