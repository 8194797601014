import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

// Load all configuration parameters from the backend
// and update values 
export class ConfigParamsService {


  constructor(
      private http: HttpClient,
  ) {
  }

  list(): Observable<Array<any>> {
    return this.http.get<Array<any>>('/api/config_params?t=' + new Date().getTime());
  }

  updateValue(id: number, new_value: string): Observable<any> {
    const params = {
      value: new_value
    };
    return this.http.put('/api/config_params/'+id, params);
  }

}
