import {Component, OnDestroy, OnInit} from '@angular/core';
import {MandantService} from '../../../services/mandant.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Mandant} from '../../../entities/Mandant';
import {Observable, Subscription} from 'rxjs';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-mandant-detail',
  templateUrl: './mandant-detail.component.html',
  styleUrls: ['./mandant-detail.component.scss']
})
export class MandantDetailComponent implements OnInit, OnDestroy {

  mandant: Mandant;
  mandForm: FormGroup;
  subs: Subscription[] = [];

  openModalDialog = false;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private fb: FormBuilder,
      private mandantService: MandantService,
      private authService: AuthService
  ) { }

  ngOnInit() {

    this.mandForm = this.fb.group({
      id: [''],
      title: ['', Validators.required],
      test: [false]
    });

    const sub: Subscription = this.route.params
        .switchMap(params => {
          const id: number = +params['id']; // (+) converts string 'id' to a number
          if (id > 0) {
            return this.mandantService.getList().map(all => { // TODO replace by single request
              return all.filter(item => item.id === id)[0];
            });
          } else {
            // create new Mandant
            const m: Mandant = new Mandant();
            m.id = 0;
            return Observable.of(m);
          }
        })
        .subscribe(mand => {
          this.mandant = mand;
          this.mandForm.patchValue({
            id: mand.id,
            title: mand.title,
            test: mand.test
          });
        });
    this.subs.push(sub);
  }

  saveMandant(m: Mandant, isValid: boolean) {
    if (isValid) {
      this.mandantService.save(m).subscribe((resp: Mandant) => {
        this.mandant = resp;
        m.id > 0 ? this.goBackToList() : this.openModalDialog = true; // open modal for new user
      });
    }
  }

  switchContextToAddNewUser(): void {
    const sub = this.authService.switchContext(this.mandant.id).subscribe(() => {
      this.router.navigate(['/users', 'new'], {queryParams: {role: 'ADMIN'}});
    });
  }

  goBackToList(): void {
    this.router.navigate(['/mandanten']);
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
  }

}
