import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Patient } from '../../../entities/Patient';
import { Subscription } from 'rxjs';
import { PatientService } from '../../../services/patient.service';
import { OperatorService } from '../../../services/operator.service';
import { AuthService } from 'src/app/services/auth.service';
import { UsbService } from 'src/app/services/usb.service';
import { NotificationService } from 'src/app/services/notification.service';
import { FeatureToggleService } from 'src/app/services/feature_toggles_service';


@Component({
  selector: 'app-patient-detail-usbstick',
  templateUrl: './patient-detail-usbstick.component.html',
  styleUrls: ['./patient-detail-usbstick.component.scss']
})
export class PatientDetailUsbstickComponent implements OnInit, OnDestroy {

  @Input() patient: Patient;

  usbStickReady: boolean = false;
  usbServiceReady: boolean = false;
  newUsbService: boolean = false;
  private usbStatusChangeSub: Subscription;

  constructor(
    private authService: AuthService,
    private patientService: PatientService,
    private operatorService: OperatorService,
    private usbService: UsbService,
    private notificationService: NotificationService,
    private featureToggleService: FeatureToggleService,
  ) { }

  ngOnInit() {
    this.featureToggleService.getFeatureStatus('new_usb_service')
      .subscribe((status) => {
        this.newUsbService = status;
      })
    this.usbStatusChangeSub = this.usbService.currentEvent.subscribe(newUsbStatus => {
      console.log('new USB status:', newUsbStatus);
      if (newUsbStatus ) {
        this.usbStickReady = newUsbStatus.status === 'USB_READY';
        this.usbServiceReady = newUsbStatus.status === 'USB_NOT_FOUND';
      }
    });
   }

   /**
    * Activate the patient to write to USB-Stick.
    * @deprecated This method is not used after activate new USB-Service.
    */
   activatePatient(){
      this.patientService.activatePatient(this.patient.pid)
        .subscribe()
  }

  downloadPatient(): void {
    this.patientService.downloadPatientEncFileForUsb(this.patient.pid).subscribe(namedFile => {
      console.log('received file', namedFile);
      const elem = document.createElement('a');
      elem.href = URL.createObjectURL(namedFile.blob);
      elem.download = namedFile.filename;
      document.body.appendChild(elem);
      elem.click();
    })
  }


  downloadToken() {
    this.authService.getUsbServiceToken().subscribe(token => this.performDownloadToken(token.token));
  }

  // solution acc to https://stackoverflow.com/a/51960768
  downloadOperators(): void {
    this.operatorService.downloadOperatorsEncFileForUsb().subscribe(namedFile => this.performDownload(namedFile));
  }

  private performDownloadToken(token: string) {
    const location = window.location;

    const host = location.protocol + '//' + location.hostname + (location.port ? ':3000' : '')

    const data = { token: "Bearer " + token, host };

    console.log(data);

    const fileName = 'config.json';

    const fileToSave = new Blob([JSON.stringify(data)], {
      type: 'application/json'
    });

    this.doPerformDownload(fileToSave, fileName);
  }

  private performDownload(namedFile): void {
    console.log('received file', namedFile);
    this.doPerformDownload(namedFile.blob, namedFile.filename);
  }

  private doPerformDownload(blob: Blob, fileName: string): void {
    const elem = document.createElement('a');
    elem.href = URL.createObjectURL(blob);
    elem.download = fileName;
    document.body.appendChild(elem);
    elem.click();
  }

  doUsbWrite(): void {
    this.usbService.savePatientAndOperator(this.patient.pid)
      .subscribe(status => {
        console.log('USB write status:', status);
        if (status) {
          this.notificationService.success('Patientendaten erfolgreich auf USB-Stick geschrieben');
        } else {
          this.notificationService.error('Fehler beim Schreiben der Patientendaten auf den USB-Stick');
        }
      })
  }




  ngOnDestroy(): void {
    this.usbStatusChangeSub.unsubscribe();
  }

}
