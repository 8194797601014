import {Component, Input, OnInit} from '@angular/core';
import {Patient} from '../../../entities/Patient';

@Component({
  selector: 'app-patient-detail-data',
  templateUrl: './patient-detail-data.component.html',
  styleUrls: ['./patient-detail-data.component.scss']
})
export class PatientDetailDataComponent implements OnInit {

  @Input() patient: Patient = null;

  constructor() { }

  ngOnInit() {
  }

}
