import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {UploadMeasurementService} from '../../../services/upload-measurement.service';
import {NotificationService} from '../../../services/notification.service';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../services/auth.service';
import {CurrentUser} from '../../../entities/CurrentUser';

@Component({
  selector: 'app-upload-measurement',
  templateUrl: './upload-measurement.component.html',
  styleUrls: ['./upload-measurement.component.scss']
})
export class UploadMeasurementComponent implements OnInit, OnDestroy {

  @Output() uploaded = new EventEmitter<any>();

  fileToUpload: File = null;
  sub: Subscription;
  currentUser: CurrentUser;
  showOverlayUploadSpinner = false;

  constructor(
      private authService: AuthService,
      private notificationService: NotificationService,
      private uploadMeasurementService: UploadMeasurementService) { }

  ngOnInit() {
      this.currentUser = this.authService.getCurrentUser();
  }

  handleFileInput(event : any) {
    console.log(event);
    const files = event.target.files;
    this.fileToUpload = files.item(0);
    this.showOverlayUploadSpinner = true;
    this.sub = this.uploadMeasurementService.postFile(this.fileToUpload).subscribe(
        () => {
            this.notificationService.success('Die Datei wurde erfolgreich hochgeladen', 'Hochladen erfolgreich');
            this.showOverlayUploadSpinner = false;
            this.uploaded.emit();
        },
        err => {
            console.log(err);
            this.showOverlayUploadSpinner = false;

            if(err.status == 409){
              throw new Error('Messung bereits vorhanden. Upload wurde ignoriert');
            } else {
              throw new Error('Die Datei konnte nicht hochgeladen/verarbeitet werden: ' + err.error);
            }
        }
    );
  }

  ngOnDestroy(): void {
    if (this.sub != null) { this.sub.unsubscribe(); }
  }

}
