import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MeasurementService} from '../../../services/measurement.service';
import {Subscription} from 'rxjs';
import 'rxjs-compat/add/observable/of';
import {MeasurementDetailsSummary} from '../../../entities/MeasurementDetailsSummary';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthService} from '../../../services/auth.service';
import {NavigationService} from '../../../services/navigation.service';

const VALIDATION_PATH = '/diagnosis/validation2.html?measurement_id=';


@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit, OnDestroy {

  measurementId: string;
  username: string;

  detailsSummary: MeasurementDetailsSummary;

  subs: Array<Subscription> = [];
  validationUrl: string = null;

  constructor(
      private route: ActivatedRoute,
      private measurementService: MeasurementService,
      private sanitizer: DomSanitizer,
      private authService: AuthService,
      private navigationService: NavigationService
  ) {}

  ngOnInit() {
    const sub: Subscription = this.route.params
        .switchMap(params => {
          const id: string = params['id']; // measurement identifier is a string !
          this.measurementId = id;
          const gUrl = this.urlForId(VALIDATION_PATH, id, this.authService.getCurrentUser().token);
          this.validationUrl = this.sanitizer.bypassSecurityTrustResourceUrl(gUrl) as string;
          return this.measurementService.getMeasurementDetails(id);
        })
        .subscribe(summary => {
            this.detailsSummary = summary;
        });
    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
  }

  /**
   * Calculate the url for the backend.
   * If run local with port 4200, then map to port 3000 for rails development server  
   * @param backendPath backend path ends with '?measurement_id='
   * @param measurementId measurement id
   * @param token authentication token (JWT)
   */
  urlForId(backendPath: string, measurementId: string, token): string {
    const location = window.location;
    return location.protocol + '//' +
        location.hostname +
        (location.port ? ':3000' : '') +
        backendPath + measurementId + '&token=' + token +
        '&use_same_host=true&t=' + new Date().getTime(); // add timestamp to avoid caching
  }



  goBack(): void {
    this.navigationService.Back();
  }
}
