import {EventEmitter, Injectable, Output} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {CurrentUser} from '../entities/CurrentUser';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Token } from '../entities/Token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  protected static LOCALSTORAGE_KEY = 'xera-analysis-app';

  @Output() onLoginSuccess: EventEmitter<void> = new EventEmitter<void>();
  @Output() onLoginFailed: EventEmitter<void> = new EventEmitter<void>();
  @Output() onLogout: EventEmitter<void> = new EventEmitter<void>();

  currentUser$: ReplaySubject<CurrentUser> = new ReplaySubject(1);

  constructor(private http: HttpClient) {
    this.currentUser$.next(this.getCurrentUserFromLocalStorage());
  }

  public getCurrentUser(): CurrentUser | null {
    return this.getCurrentUserFromLocalStorage();
  }

  public hasCurrentUser(): boolean {
    return (this.getCurrentUserFromLocalStorage() != null);
  }

  public getUsbServiceToken(): Observable<Token>{
    return this.http.get<Token>('/api/auth/usb-service-token');
  }

  public login(username: string, password: string) {
    this.http.post<any>(
      '/api/auth/login',
      {
        username,
        password,
      })
      .map(resp => {
        console.log('login resp for \'' + username + '\'', resp);
        const u: CurrentUser = CurrentUser.create(resp.token); // new User(resp.username, resp.token);
        return u;
      })
      .subscribe( u => {
        this.persistCurrentUserToLocalStorage(u);
        this.onLoginSuccess.emit(); // fire event
        this.currentUser$.next(u);
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.log('An error occurred:', err.error.message);
          this.onLoginFailed.emit();
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.log(`Backend returned code ${err.status}, body was: `, err.error);
          this.onLoginFailed.emit();
        }
      });
  }

  public switchContext(mandantId: number): Observable<any> {
    return this.http.patch('/api/auth/switch-context/' + mandantId, null);
  }

  public refreshToken(token: string) {
    console.log('refresh token', token.substring(0, 20) + '...');
    /*
    const user: CurrentUser = this.getCurrentUserFromLocalStorage();
    if (user == null) { throw new Error('cant update user (null)'); }
    user.token = token;
    this.persistCurrentUserToLocalStorage(user);
    */
    const user: CurrentUser = CurrentUser.create(token);
    this.persistCurrentUserToLocalStorage(user);
    this.currentUser$.next(user);
  }

  /**
   * reset password at backend for currently logged in user
   */
  public resetpasswd(oldpw: string, newpw: string): Observable<any> {
    const body = { oldpw : oldpw, newpw : newpw };
    return this.http.patch('/api/reset-password', JSON.stringify(body));
  }

  public logout(): void {
    this.removeCurrentUserFromLocalStorage();
    this.onLogout.emit();
    this.currentUser$.next(null);
  }

  /**
   * protected methods
   */
  protected getCurrentUserFromLocalStorage(): CurrentUser | null {
    if (localStorage.getItem(AuthService.LOCALSTORAGE_KEY) != null) {
      const userdata = JSON.parse(localStorage.getItem(AuthService.LOCALSTORAGE_KEY));
      return Object.assign(new CurrentUser(), userdata);
    }
    this.logout();
    return null;
  }

  protected persistCurrentUserToLocalStorage(currentUser: CurrentUser) {
    localStorage.setItem(AuthService.LOCALSTORAGE_KEY, JSON.stringify(currentUser));
  }

  protected removeCurrentUserFromLocalStorage() {
    localStorage.removeItem(AuthService.LOCALSTORAGE_KEY);
  }
}
