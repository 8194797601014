import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PatientService} from '../../../services/patient.service';
import {Patient} from '../../../entities/Patient';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-patient-edit',
  templateUrl: './patient-edit.component.html',
  styleUrls: ['./patient-edit.component.scss']
})
export class PatientEditComponent implements OnInit, OnDestroy {

  patient: Patient;
  patForm: FormGroup;
  subs: Subscription[] = [];

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private fb: FormBuilder,
      private patientService: PatientService
  ) { }

  get formControls() {
    return this.patForm.controls;
  }

  ngOnInit() {
    this.patForm = this.fb.group({
      id: [''],
      pid: ['', [Validators.required, Validators.max(999999999), Validators.min(10)]], // mind 2, max 9 stellig
      name: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9]{3,5}$')]]
    });

    const sub: Subscription = this.route.params
        .switchMap(params => {
          const pid: number = +params['pid']; // (+) converts string 'id' to a number
          if (pid > 0) {
            return this.patientService.getPatient(pid);
          } else {
            const p: Patient = new Patient();
            p.id = 0;
            p.pid = null;
            p.name = null;
            return Observable.of(p);
          }
        })
        .subscribe(patient => {
          this.patient = patient;
          this.patForm.patchValue({
            id: patient.id,
            pid: patient.pid,
            name: patient.name
          });
        });
    this.subs.push(sub);
  }

  savePatient(p: Patient, isValid: boolean) {
    if (isValid) {
      this.subs.push(
          this.patientService.savePatient(p).subscribe(() => {
            // go back to list
            this.router.navigate(['/patienten']);
          })
      );
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
  }

}
