import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {NamedFile} from '../types/NamedFile';
import {DownloadOctetStreamNamedFile} from '../utilities/DownloadOctetStreamNamedFile';
import {HttpClient} from '@angular/common/http';
import {OperatorView} from '../entities/OperatorView';
import {SaveOperatorDto} from '../entities/SaveOperatorDto';

@Injectable({
  providedIn: 'root'
})
export class OperatorService {

    constructor(private http: HttpClient) { }

    public getList(): Observable<Array<OperatorView>> {
        return this.http.get<Array<OperatorView>>('/api/operators');
    }

    public saveOperator(o: SaveOperatorDto): Observable<OperatorView> {
        if (o.id) {
            // update existing
            return this.http.put<OperatorView>('/api/operators/'+o.id, JSON.stringify(o));
        }
        // create new
        return this.http.post<OperatorView>('/api/operators', JSON.stringify(o));
    }

    public deleteOperator(opId: string): Observable<OperatorView> {
        // delete existing
        return this.http.delete<OperatorView>('/api/operators/'+opId);
    }

    public downloadOperatorsEncFileForUsb(): Observable<NamedFile> {
        return DownloadOctetStreamNamedFile.download(this.http, '/api/operators/download');
    }
}
