import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs-compat/add/operator/do';
import {Patient} from '../entities/Patient';
import {NamedFile} from '../types/NamedFile';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private httpClient: HttpClient) {
  }

  public getPatientList(): Observable<Array<Patient>> {
    return this.httpClient
        .get('/api/patients')
        .map((resp: Array<any>) => {
          const plist: Array<Patient> = [];
          resp.forEach(item => plist.push(Patient.parse(item)));
          return plist;
        });
  }

  public getPatient(pid: number): Observable<Patient> {
    return this.httpClient
        .get('/api/patients/' + pid)
        .map(resp => Object.assign(Patient.parse(resp)));
  }

  public activatePatient(patientId: number): Observable<any> {
    const dto = {
      patient_id: patientId
    };

    return this.httpClient
      .post('/api/command/request_usb_stick_write', JSON.stringify(dto));
  }

  public downloadPatientEncFileForUsb(patientId: number): Observable<NamedFile> {
      return this.httpClient.get('/api/patients/' + patientId + '/file', {
          headers: new HttpHeaders({Accept: 'application/octet-stream'}),
          observe: 'response',
          responseType : 'blob' // new to Angular4, before it was defined as {responseType: ResponseContentType.Blob}
      }).switchMap((resp: HttpResponse<Blob>) => {
          let filename = 'unknown';
          const contentDisposition = resp.headers.get('Content-Disposition');
          console.log('headers', resp.headers);
          if ((typeof contentDisposition !== 'undefined') && (contentDisposition != null )) {
              // filename = contentDisposition.split(';')[2].split('filename')[1].split('=')[1].trim();
              filename = contentDisposition.match('filename="(.*)"')[1];
              console.log('filename', filename);
          }
          const result: NamedFile = {
              blob: new Blob([resp.body], { type: 'application/octet-stream' }),
              filename: filename
          };
          return Observable.of(result);
      });
  }

  public savePatient(p: Patient): Observable<any> {
    const dto = {
        id: p.id,
        patientId: p.pid,
        nickname: p.name
    };

    return this.httpClient
      .put('/api/patients/', JSON.stringify(dto));
  }

}
