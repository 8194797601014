import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UsbServiceStatus } from '../entities/UsbServiceStatus';
import { UsbStickCounts } from '../types/usb_service_types';
import { MeasurementService } from '../services/measurement.service';
import { startWith, switchMap, catchError } from 'rxjs/operators';
import { BehaviorSubject, interval, Subscription, EMPTY  } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';

export const LOCAL_USB_SERVICE_URL = 'http://localhost:8933/';

@Injectable({
  providedIn: 'root'
})
export class UsbService {
  private eventSource = new BehaviorSubject<any>(null); // You can use any data type here
  currentEvent = this.eventSource.asObservable();
  private eventUsbCountSource = new BehaviorSubject<UsbStickCounts>(null); // You can use any data type here
  currentUsbStickCounts = this.eventUsbCountSource.asObservable();
  usbServiceSub: Subscription;


  private lastUsbServiceStatus: UsbServiceStatus = { 
    status: 'USB_SERVICE_NOT_AVAILABLE', 
    version: 'n.v.', 
    usb_path: '', 
    error: 'Error connecting to USB service' 
  };

  constructor(
    private httpClient: HttpClient,
    private measurementService: MeasurementService,
    private notificationService: NotificationService,
  ) {
    // init the status
    this.eventSource.next(this.lastUsbServiceStatus);
    this.getStatus().subscribe(
      status => {
        this.sendEvent(status);
      },
      error => {
        console.error('Error getting USB service status', error);
      }
    );
    // init the counts
    this.eventUsbCountSource.next({ totalCount: 0, uploadedCount: 0 });
  }

  startUsbService() {
    console.log('USB Service - Start cyclical query');
    const repeatInterval = window.location.hostname === '127.0.0.1' ? 10000 : 5000;

    this.usbServiceSub = interval(repeatInterval).pipe(
      startWith(0),
      switchMap(() => this.getStatus().pipe(
        catchError((error) => {
          console.error('Error connecting to USB service:', error);
          this.sendEvent({ 
            version: 'n.v.', usb_path: '', 
            error: 'Error connecting to USB service', 
            status: 'USB_SERVICE_NOT_AVAILABLE' });
          return EMPTY;
        })
      ))
    ).subscribe((status) => {
      this.sendEvent(status); // Send status to all subscribed components
    });
  }

  /**
   * Send USB-Service-Status-Change event to all components that are interested in it.
   * @param newUsbStatus The new status to send to all subscribed components.
   */
  sendEvent(newUsbStatus: UsbServiceStatus) {
    if (this.lastUsbServiceStatus.status === newUsbStatus.status) {
      return;
    }
    console.log('USB Service status changed to ' + newUsbStatus.status);
    this.lastUsbServiceStatus = newUsbStatus;
    if (newUsbStatus.status === 'USB_READY') {
      this.checkNewMeasurements();
    }
    this.eventSource.next(newUsbStatus);
  }

  /**
   * Start download of the USB service installer.
   * @returns {Observable<Blob>} The installer as a blob.
   */
  public download(): Observable<Blob> {
    const location = window.location;

    const host = location.protocol + '//' + location.hostname + (location.port ? ':3000' : '')
    return this.httpClient
      .get("/api/usb-service/download?host=" + host, { responseType: 'blob' });
  }

  public downloadUsbServiceInstaller = (): Observable<Blob> => {
    return this.httpClient.get('/xera3-usb-service-installer.zip?ts='+Date.now(), { responseType: 'blob', headers: { 'skip': 'Content-Type' }});
  }

  public getStatus(): Observable<UsbServiceStatus> {
    console.log('Getting USB service status from ' + LOCAL_USB_SERVICE_URL);
    return this.httpClient.get<UsbServiceStatus>(LOCAL_USB_SERVICE_URL);
  }

  public savePatientFile(patientId: number): Observable<boolean> {
    return this.downloadPatientData(patientId).pipe(
      switchMap(data => {
        return this.httpClient.post<boolean>(LOCAL_USB_SERVICE_URL + 'save_patient_file', data);
      })
    );
  }

  downloadPatientData(patientId: number): Observable<Blob> {
    return this.httpClient.get('/api/patients/' + patientId + '/file', { responseType: 'blob' });
  }

  public saveOperatorsFile(): Observable<boolean> {
    return this.downloadOperatorsData().pipe(
      switchMap(data => {
        return this.httpClient.post<boolean>(LOCAL_USB_SERVICE_URL + 'save_operators_file', data);
      })
    );
  }

  downloadOperatorsData(): Observable<Blob> {
    return this.httpClient.get('/api/operators/download', { responseType: 'blob' });
  }


  checkNewMeasurements() {
    console.log('Checking for new measurements');
    const emptyResult = { totalCount: 0, uploadedCount: 0 }
    this.httpClient.get(LOCAL_USB_SERVICE_URL + 'measure_ids').subscribe(
      (measureIds: Array<number>) => {
        if (measureIds.length === 0) {
          console.log('No measurements to upload');
          this.eventUsbCountSource.next(emptyResult);
        }
        this.measurementService.checkMeasurementIdentifiers(measureIds).subscribe(
          (missingMeasureIds: Array<number>) => {
            if (missingMeasureIds.length > 0) {
              console.log("Don't exist measurements: " + missingMeasureIds);
              for (let measureId of missingMeasureIds) {
                this.uploadMeasurement(measureId);
              }
            } else {
              console.log('All measurements uploaded');
            }
            const countResult = { totalCount: measureIds.length, uploadedCount: measureIds.length - missingMeasureIds.length };
            // update the counts
            this.eventUsbCountSource.next(countResult);
          },
          (error) => {
            console.error('Error checking measurements', error);
            this.eventUsbCountSource.next(emptyResult);
          }
        );
      },
      (error) => {
        console.error('Error getting measure ids', error);
        this.eventUsbCountSource.next(emptyResult);
      }
    );
  }

  uploadMeasurement(measure_id: number) {
    console.log('Uploading measurements ' + measure_id);
    this.httpClient.get(LOCAL_USB_SERVICE_URL + 'measure_file?measure_id=' + measure_id).subscribe(
      (data: string) => {
        console.log('uploadMeasurement data loaded from local USB-Svc: size=', data.length);
        this.measurementService.uploadMeasurement(data).subscribe(
          (result) => {
            console.log('uploadMeasurement done', result);
            this.notificationService.success('Messung ' + measure_id + ' erfolgreich hochgeladen');
          },
          (error) => {
            console.error('Error uploadMeasurement', error);
            this.notificationService.error('Fehler beim Hochladen der Messung ' + measure_id + ': ' + error.error);
          }
        );
      },
      (error) => {
        console.error('Error uploadMeasurement load data', error);
        this.notificationService.error('Fehler beim Laden der Messung ' + measure_id + ': ' + error.error);
      }
    );
  }

  public savePatientAndOperator(patientId: number): Observable<boolean> {
    return this.savePatientFile(patientId).pipe(
      switchMap(() => {
        return this.saveOperatorsFile();
      })
    );
  }
}