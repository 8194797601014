import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {CurrentUser} from '../../entities/CurrentUser';
import {Event} from '../../entities/Event';
import {BehaviorSubject, Subscription} from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { interval } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UsbService } from 'src/app/services/usb.service';
import { DiagnosisService } from 'src/app/services/diagnosis.service';
import {saveAs} from 'file-saver';
import { FeatureToggleService } from 'src/app/services/feature_toggles_service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {
  [x: string]: any;

  currentUser: CurrentUser = null;
  subs: Array<Subscription> = [];
  newUsbService: boolean = false;


  constructor(private authService: AuthService, 
    private notificationService: NotificationService,
    private usbService: UsbService,
    private DiagnosisService: DiagnosisService,
    private featureToggleService: FeatureToggleService,
  ) {}

  ngOnInit() {

    this.subs.push(this.featureToggleService.getFeatureStatus('new_usb_service')
      .subscribe((status) => {
        this.newUsbService = status;
      })
    );

    this.subs.push(
      this.authService.currentUser$.subscribe(cu => this.currentUser = cu)
    );
    const repeatInterval = window.location.hostname === '127.0.0.1' ? 60000 : 5000;
    this.subs.push(
      interval(repeatInterval)
      .pipe(
        startWith(0),
        switchMap(
          () => this.notificationService.getBackEndMessages()
                    .pipe(catchError(() => {return EMPTY}))
        )
      ).subscribe((event: Event) => {
        if(event){
          if(event.status === "OK"){
            this.notificationService.success(event.description);
          } else {
            this.notificationService.error(event.description);
          }
  
          this.notificationService.readMessage(event.id).subscribe()
        }
      }, err => console.log(err))
    )
  }

  logout(): void {
    this.authService.logout();
  }

  downloadUsbService(){
    this.usbService.download()
      .subscribe(blob => {
        console.log(blob);
        const blobFile = new Blob([blob], { type: 'application/zip' });
        const fileName = 'installer.zip';
        saveAs(blobFile, fileName);
      })
  }

  downloadNewUsbService(){
    this.usbService.downloadUsbServiceInstaller()
      .subscribe(blob => {
        console.log(blob);
        const blobFile = new Blob([blob], { type: 'application/zip' });
        const fileName = 'xera3-usb-service-installer.zip';
        saveAs(blobFile, fileName);
      })
  }

  /**
   * This component has a button that, when clicked, 
   * triggers the downloadDiagnosisData method. 
   * This method calls the service, receives the blob data, creates a URL for it, 
   * and then triggers the download using an invisible <a> element.
   */
  downloadDiagnosisData(){
    this.DiagnosisService.getAll()
      .subscribe(data => {
        const blob = new Blob([data], { type: 'application/json' });
        const url= window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'diagnosis_list.json';
        link.click();
        window.URL.revokeObjectURL(url);     
      })
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
  }

}  

