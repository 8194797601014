import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NamedFile} from '../types/NamedFile';

export class DownloadOctetStreamNamedFile {

    static download(httpClient: HttpClient, url: string): Observable<NamedFile> {
        return httpClient.get(url, {
            headers: new HttpHeaders({Accept: 'application/octet-stream'}),
            observe: 'response',
            responseType: 'blob' // new to Angular4, before it was defined as {responseType: ResponseContentType.Blob}
        }).switchMap((resp: HttpResponse<Blob>) => {
            let filename = 'unknown';
            const contentDisposition = resp.headers.get('Content-Disposition');
            console.log('headers', resp.headers);
            if ((typeof contentDisposition !== 'undefined') && (contentDisposition != null)) {
                // filename = contentDisposition.split(';')[2].split('filename')[1].split('=')[1].trim();
                filename = contentDisposition.match('filename="(.*)"')[1];
                console.log('filename', filename);
            }
            const result: NamedFile = {
                blob: new Blob([resp.body], {type: 'application/octet-stream'}),
                filename: filename
            };
            return Observable.of(result);
        });
    }
}
