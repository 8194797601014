import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OperatorService} from '../../../services/operator.service';
import {Observable, Subscription} from 'rxjs';
import {OperatorView} from '../../../entities/OperatorView';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'app-operator-detail',
  templateUrl: './operator-detail.component.html',
  styleUrls: ['./operator-detail.component.scss']
})
export class OperatorDetailComponent implements OnInit, OnDestroy {

    operator: OperatorView;
    opForm: FormGroup;
    subs: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private operatorService: OperatorService,
        private notificationService: NotificationService
        ) { }

    ngOnInit() {
        this.opForm = this.fb.group({
            id: [''],
            nickname : ['', [Validators.required, Validators.pattern('^[A-Za-z0-9]{3,5}$')]],
            pin: ['', [Validators.required, Validators.pattern('^[0-9]{4}$'), Validators.min(1000), Validators.max(9999)]]
        });

        const sub: Subscription = this.route.params.switchMap(params => {
            const id: number = + params['id'];
            if (id > 0) {
                return this.operatorService.getList().map(list => { // TODO replace by single request
                    return list.filter(item => item.id === id)[0];
                });
            } else  {
                const o: OperatorView = new OperatorView();
                o.id = 0;
                return Observable.of(o);
            }
        }).subscribe(op => {
            this.operator = op;
            this.opForm.patchValue({
               id: op.id,
               nickname: op.name,
               pin: op.pin
            });
        });
        this.subs.push(sub);
    }

    /**
     * Save Operator: wird zugeordnet zu Mandant des aktiven Contexts
     * @param value
     * @param valid
     */
    saveOperator(value: any, valid: boolean) {
        if (valid) {
            const body = {
                id: value.id,
                nickname: value.nickname,
                pin: value.pin
            };

            this.operatorService.saveOperator(body).subscribe(resp => {
                this.notificationService.success('Operator gespeichert');
               this.router.navigate(['/operators']);
            });
        }

    }

    /**
     * Delete Operator
     * @param opId
     */
    deleteOperator(opId: string) {
        this.operatorService.deleteOperator(opId).subscribe(resp => {
            this.notificationService.success('Operator gelöscht');
            this.router.navigate(['/operators']);
        });

    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }
}
