import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../services/user.service';
import {ManagableUser} from '../../../entities/ManagableUser';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {RoleService} from '../../../services/role.service';
import {Role} from '../../../entities/Role';
import 'rxjs-compat/add/operator/first';
import {AuthService} from '../../../services/auth.service';
import 'rxjs-compat/add/operator/mergeMap';
import {CurrentUser} from '../../../entities/CurrentUser';
import {SaveUserDto} from '../../../entities/SaveUserDto';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {

  user: ManagableUser;
  userForm: FormGroup;
  subs: Subscription[] = [];
  available_roles: Array<String> = [];
  assigned_roles: Array<String> = [];
  self = this;

  currentUser: CurrentUser = null;

  get formControls() {
    return this.userForm.controls;
  }

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private fb: FormBuilder,
      private userService: UserService,
      private roleServices: RoleService,
      private authService: AuthService,
      private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.userForm = this.fb.group({
      id: [''],
      username: ['', Validators.required],
      // email: ['', Validators.required],
      enabled: ['', Validators.required],
      password : ['', Validators.minLength(6)],
      fc_assigned_roles : [''],
      fc_available_roles: ['']
    });

    const rolesObs = this.roleServices.getAssignableRoles().first();
    const paramsObs = this.route.params.first();
    const queryParamObs = this.route.queryParams.do(load => console.log('queryPObs', load)).first();

    // in case of a given user id, we want to edit a user

    const sub = forkJoin([rolesObs, paramsObs, queryParamObs])
        .switchMap ((responseList) => {
          const roles = responseList[0];
          const params = responseList[1];
          const queryParams = responseList[2];

          console.log('forkjoin', responseList);

          // set available roles
          this.available_roles = roles;
          this.userForm.patchValue({'fc_available_roles': []}); // nothing should be marked

          // if 'id' is given, we want to edit an existing user
          const id: number = +params['id']; // (+) converts string 'id' to a number
          if (id > 0) {
            // load user
            return this.userService.getMyUsers().map(u => u.filter(item => item.id === id)[0]);
          } else {
            // create new ManagableUser
            const m: ManagableUser = new ManagableUser();
            m.id = 0;
            m.enabled = true;

            // and load preset mandant and preassigned roles from query params
            m.mandant = null; // must be set by queryParam
            m.roles = [];
            if ('role' in queryParams) {
              // add role to user
              const new_user_add_role = String(queryParams['role']).toUpperCase();
              this.available_roles
                  .filter(role => role === new_user_add_role) // its only one but type array
                  .forEach(role => {
                    this.assigned_roles.push(role);
                    m.roles.push(role);
                  });
            }
            return Observable.of(m);
          }
        })
        .subscribe(u => {
          console.log('filtered', u);
          this.user = u;
          this.assigned_roles = u.roles;
          u.roles.forEach(element => {
            this.available_roles.splice(this.available_roles.indexOf(element), 1);
          });
          
          this.userForm.patchValue({
            id: u.id,
            username: u.username,
            // email: u.email,
            enabled: u.enabled,
            fc_assigned_roles: [],
            fc_available_roles: []
          });
        });
    this.subs.push(sub);

    this.subs.push(this.authService.currentUser$.subscribe(u => this.currentUser = u));
  }

  /*
   * ---------------------------------------
   * ROLES
   * --------------------------------------- */

  /**
   * assign all available roles
   */
  select_all_roles() {
    this.available_roles.forEach(role => this.assigned_roles.push(role));
    this.available_roles = [];
    this.patchAssignedRoles();
  }

  /**
   * add the selected available role(s) to the assigned roles, if not already exist (no dplicates)
   */
  select_role() {
    const roles: string[] = this.userForm.controls['fc_available_roles'].value;
    roles.forEach(role => {
        this.assigned_roles.push(role);
        this.available_roles.splice(this.available_roles.indexOf(role), 1);
    });
    this.patchAssignedRoles();
  }

  /**
   * remove selected assigned role(s)
   */
  deselect_role() {
    const roles: string[] = this.userForm.controls['fc_assigned_roles'].value;
    roles.forEach( (role, index) => {
      this.assigned_roles.splice(this.assigned_roles.indexOf(role), 1);
      this.available_roles.push(role);
    });
    this.patchAssignedRoles();
  }

  /**
   * remove all assigned roles
   */
  deselect_all_roles() {
    this.assigned_roles.forEach(role => this.available_roles.push(role));
    this.assigned_roles = [];
    this.patchAssignedRoles();
  }

  protected patchAssignedRoles(): void {
    this.userForm.patchValue({'fc_assigned_roles': []}); // this.assigned_roles.map(r => r.id)});
    this.userForm.patchValue({'fc_available_roles': []}); // this.assigned_roles.map(r => r.id)});
  }

  /*
   * ---------------------------------------
   * SAVE
   *
   * der Mandant, für den der Nutzer angelegt wird wird aus dem Context des eingeloggten Nutzers genommen
   * --------------------------------------- */

  saveUser(userFormObject: any, isValid: boolean) {
    if (isValid) {
      // map form model to entity
      const user: SaveUserDto = new SaveUserDto();
      user.id = userFormObject.id;
      user.username = userFormObject.username;
      // user.email = userFormObject.email;
      user.enabled = userFormObject.enabled;
      if (userFormObject.password != null && userFormObject.password.length > 0) {user.password = userFormObject.password; }
      user.roles = this.assigned_roles;

      this.userService.saveUser(user).subscribe(() => {
            this.notificationService.success('Nutzer gespeichert');
            this.router.navigate(['/users']);
          },
          err => {
            switch (err.error.error) {
              case 'Forbidden':
                throw new Error('Sie haben kein ausreichenden Rechte, um diese Operation durchzuführen');
              default:
                throw new Error('Das Speichern ist fehlgeschlagen. Bitte achten Sie darauf, dass ' +
                    'Benutzernamen systemweit nicht doppelt vergeben werden können und ein Passwort sowie mind. 1 Rolle bekommen müssen.');
            }
          });
    }
  }


  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
  }

    /**
     * take rolename, search for role in assigned_roles and take the id
     * search with this id in selected roles
     * if we find a selected role with this, return true, otherwise false
     *
     * @param {string} roleName
     * @returns {boolean}
     */
  isRoleSelected(roleName: string): boolean {
      if (roleName == null) { return false; }
      if (this.assigned_roles == null) { return false; }
      const role = this.assigned_roles.find(r => r === roleName);
      return (role != null);
  }
}
