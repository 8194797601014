import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {CurrentUser} from '../entities/CurrentUser';
import {NotificationService} from '../services/notification.service';
import {LOCAL_USB_SERVICE_URL} from '../services/usb.service';

const DEV_BACKEND = 'https://praxis-dev.app.xera3.com';
const usb_service_url = LOCAL_USB_SERVICE_URL

@Injectable()
export class BackendInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) {}


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    if(req.url.startsWith(usb_service_url)) {  
      // frontend is calling local USB-Service
      // do not intercept this request
      return next.handle(req);
    }


    const authService: AuthService = this.injector.get(AuthService);
    const notificationServer: NotificationService = this.injector.get(NotificationService);

    const currentUser: CurrentUser = authService.getCurrentUser();
    const isAuthenticationRequest: boolean = (req.url.startsWith('/api/login') || (currentUser == null) || !('token' in currentUser));


    const location = window.location;
    if (location.hostname === 'localhost' || location.hostname === '127.0.0.1x') {
      req = req.clone(
        {
          url: DEV_BACKEND + req.url,
        }
      );
    } else {
      // if not localhost, use the same domain as the frontend
      req = req.clone(
        {
          url:
            location.protocol + '//' +
            location.hostname +
            (location.port ? ':3000' : '') + // use port 3000 for backend (Rails-Bakend)
            location.pathname +
            req.url.substr(1),
        }
      );
    }


    //if (!req.url.endsWith('/api/measurements')) {
    const skipFlag = 'skip';
    // console.log('before header manipulation', req.headers);
    if (req.headers.has(skipFlag) && req.headers.get(skipFlag) === 'Content-Type') {
        // console.log("found skip flag, thus do not add content-type json");
        req.headers.delete(skipFlag);
    } else {
        // console.log("add content type json");
        req = req.clone({
            setHeaders: {
                'Content-Type': 'application/json'
            }
        });
    }
    // console.log('after header manipulation', req.headers);

    /*
    const ctype = 'Content-Type';
    console.log("headers: ", req.headers.has('setMyType'), req.headers.get(ctype));
    if (req.headers.has(ctype) && req.headers.get(ctype) === 'multipart/form-data'){
        //do nothing, skip adding a header
        console.log("remove content type def");
        req.headers.delete(ctype);
        console.log("headers: ", req.headers.has(ctype), req.headers.get(ctype));
    }
    else {
        //add already set header or use default json
        req = req.clone({
            setHeaders: {
              'Content-Type': req.headers.has(ctype) ? req.headers.get(ctype) : 'application/json'
            }
        });
    }
    */



    if (isAuthenticationRequest) {
      return next.handle(req); // exit interceptor here, if its a login request
    }

    // otherwise add Auth token to the request
    req = req.clone(
      {
        setHeaders: {
          'Authorization': `Bearer ${currentUser.token}`,
        },
      });

    return next.handle(req)
      // take the refreshed token from response and update user via auth service
      .do((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // backend must set 'Access-Control-Expose-Headers: Authorization' to read this header in frontend
          const token: string = event.headers.get('authorization');
          if (token != null && token.startsWith('Bearer ')) {
            authService.refreshToken(token.substring(7));
            // notificationServer.info(token, "refresh 'Authorization' token");
          } else {
            // notificationServer.warn('unable to find \'Authorization\' header in (' + event.headers.keys().join('; ') + '). ' +
            //   'Request was ' + req.method + ' ' + req.url, 'refresh jwt token');
          }
        }
        // return event;
      });
  }
}
