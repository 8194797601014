export class MeasurementDetailsSummary {
    frequencies: FrequenciesSummary;
    comments: Array<CommentRead>;
    rightElectrodeSize: String;
    leftElectrodeSize: String;
    crdate: String;
    patientName: String;
    patientNumber: Number;
}

export class FrequenciesSummary {
    list: Array<number>;
    min: number;
    max: number;
}

export class CommentRead {
    content: string;
    creationDate: string;
    nick: string;
}
