import * as moment from 'moment';

export class MeasurementStub {
    identifier: string;
    measurement_date: Date;
    measurement_date_iso: Date;
    has_diagnosis: boolean;
    validate_state: string;

    public static parse(rawData: any): MeasurementStub {
        const result = new MeasurementStub();
        const valstate = rawData['validate_state'];
        result.identifier = 'identifier' in rawData ? rawData['identifier'] : null;
        result.measurement_date = 'measurement_date' in rawData
            ? moment(rawData['measurement_date'], 'DD.MM.YYYY HH:mm').toDate()
            : null;
        result.measurement_date_iso = 'measurement_date_iso' in rawData ? rawData['measurement_date_iso'] : null;
        result.has_diagnosis = 'has_diagnosis' in rawData ? rawData['has_diagnosis'] : null;
        result.validate_state = 'validate_state' in rawData ? rawData['validate_state'] : null;
        if (valstate) {
            if (valstate === 'valid') {
                result.validate_state = 'Gültig';
            } else if (valstate === 'unvalidated') {
                result.validate_state = 'Ungeprüft';
            } else if (valstate === 'invalid') {
                result.validate_state = 'Ungültig';
            } else {
                result.validate_state = 'Unbekannt';
            }
        }
        return result;
    }
}
