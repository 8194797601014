import {AfterViewInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';

/**
 * This class provide common datatable functionality to a list view component
 */
export abstract class AbstractDataTableComponent implements AfterViewInit {

  protected table_name: string;

  dtOptions: DataTables.Settings = {
    pagingType: 'full_numbers',
    pageLength: 25,
    lengthMenu: [10, 25, 50, 100, 500]
  };

  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject();

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  constructor(tableName: string) {
    this.table_name = tableName;
  }

  // do not trigger dt inside subscription, this will run into problemes with dt rendering
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  /**
   * this method must be called, when data have been refreshed -- typically in subscription of API response
   */
  rerender(): void {
    if ((this.dtElement != null) && (this.dtElement.dtInstance != null)) {
      console.log('rerender datatable list', this.table_name);
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
  }

}
