import * as moment from 'moment';

export class AnamnesenStub {
    id: string;
    created_at: Date;
    formserver_url: string;

    public static parse(rawData: any): AnamnesenStub {
        const result = new AnamnesenStub();
        result.id = 'id' in rawData ? rawData['id'] : null;
        result.created_at = 'created_at' in rawData
            ? moment.parseZone(rawData['created_at']).toDate()
            : null;
        result.formserver_url = 'formserver_url' in rawData ? rawData['formserver_url'] : null;
        return result;
    }
}
