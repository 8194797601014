import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs-compat/add/operator/do';
import {Comment} from '../entities/Comment';

@Injectable({
    providedIn: 'root'
})

export class CommentService {

    constructor(
        private httpClient: HttpClient
    ) {}

    public saveComment(c: Comment): Observable<any> {
        const dto = {
            content: c.content,
            username: c.username,
            measurementId: c.measurement_id
        };

        return this.httpClient
            .post('/api/measurements/' + c.measurement_id + '/comments', JSON.stringify(dto));
            // reload list after save
            // .do(() => this.fetchData());
    }
}