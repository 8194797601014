import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractDataTableComponent} from '../../generics/AbstractDataTableComponent';
import {Subscription} from 'rxjs';
import {MandantService} from '../../../services/mandant.service';
import {Mandant} from '../../../entities/Mandant';
import {AuthService} from '../../../services/auth.service';
import {CurrentUser} from '../../../entities/CurrentUser';

@Component({
  selector: 'app-mandant-list',
  templateUrl: './mandant-list.component.html',
  styleUrls: ['./mandant-list.component.scss']
})
export class MandantListComponent extends AbstractDataTableComponent implements OnInit, OnDestroy {

  currentUser: CurrentUser;
  mandants: Array<Mandant> = [];
  subs: Array<Subscription> = [];
  openModalDialog = false;
  deleteMandantId = null;

  constructor(
      private mandantService: MandantService,
      private authService: AuthService
  ) {
    super('mandant-list');
  }

  ngOnInit() {
    this.subs.push(this.getList());


    this.subs.push(
        this.authService.currentUser$.subscribe(u => this.currentUser = u)
    );

    this.dtOptions.order = [
      [0, 'asc'], // nach ID ordnen als default
    ];
  }

  getList(){
    return this.mandantService.getList().subscribe((mands) => {
      this.mandants = mands;
      this.rerender();
    })
  }

  switchContext(mandantId: number) {
    this.authService.switchContext(mandantId).subscribe(() => {}); // TODO: this should re-route to a dashboard
  }

  activateDeleteModal(mandantId: number){
    this.deleteMandantId = mandantId;
    this.openModalDialog = true;
  }

  deleteMandant(){
    this.deleteMandantId && this.subs.push(
      this.mandantService.deleteMandant(this.deleteMandantId).subscribe(() => {
        this.openModalDialog = false;
        this.deleteMandantId = null;
        this.getList();
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
  }
}
