import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from './services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  // indicates that we have an authenticated user
  hasUserAuth: boolean;

  subs: Array<Subscription> = [];

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    this.hasUserAuth = false;
  }

  ngOnInit(): void {
    this.subs.push(
      this.authService.currentUser$.subscribe(cu => {
        this.hasUserAuth = (!(typeof cu === 'undefined') && (cu != null));
        if (!this.hasUserAuth) { this.router.navigate(['/login'], {queryParams: {logout: true} }); }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
  }

}
