import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {Mandant} from '../entities/Mandant';

@Injectable({
  providedIn: 'root'
})
export class MandantService {

  constructor(private http: HttpClient) {
      // this.fetchdata();
  }

  public getList(): Observable<Array<Mandant>> {
    return this.http
        .get('/api/mandants')
        .map((resp: Array<any>) => {
          const result: Array<Mandant> = [];
          resp.forEach( item => result.push(Object.assign(new Mandant(), item)));
          return result;
        });
  }

  public getMandant(id: number): Observable<Mandant> {
      return this.http.get<Mandant>('/api/mandants/' + id);
  }

  public deleteMandant(id: number): Observable<void> {
    return this.http.delete<void>('/api/mandants/' + id);
  }

  public save(m: Mandant): Observable<Mandant> {
      const body: string = JSON.stringify(m);
      return this.http.put<Mandant>('/api/mandants', body);
  }
}
