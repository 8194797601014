import {Component, OnDestroy, OnInit} from '@angular/core';
import {PatientService} from '../../../services/patient.service';
import {Patient} from '../../../entities/Patient';
import {Subject, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import 'rxjs-compat/add/operator/switchMap';
import {NavigationService} from '../../../services/navigation.service';

@Component({
  selector: 'app-patient-detail-menue',
  templateUrl: './patient-detail-menue.component.html',
  styleUrls: ['./patient-detail-menue.component.scss']
})
export class PatientDetailMenueComponent implements OnInit, OnDestroy {

  activeTab = 'data';
  patient: Patient = null;
  reloadMeasurementsSub: Subject<boolean> = new Subject<boolean>();
  subs: Array<Subscription> = [];

  constructor(
      private route: ActivatedRoute,
      private patientService: PatientService,
      private navigationService: NavigationService
  ) { }

  ngOnInit() {
    const sub: Subscription = this.route.params.switchMap(params => {
      const pid: number = +params['pid']; // (+) converts string 'id' to a number
      return this.patientService.getPatient(pid);
    }).subscribe(patient => this.patient = patient);

    this.subs.push(sub);
  }

  reloadMeasurements(){
    this.reloadMeasurementsSub.next(true);
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
  }

  showTab(val: string): void {
    this.activeTab = val;
  }

  goBack(): void {
    this.navigationService.Back();
  }
}
