/**
 * The current user is parsed from JWT Token and we have just roles and priviliges as values no ids)
 */
import {JwtHelperService} from '@auth0/angular-jwt';


export class CurrentUser {
  userId: number;
  username: string;
  mandant_id: number;
  mandant_title: string;
  token: string; // jwt token
  roles: Array<string>; // role values
  privileges: Array<string>; // all privileges from all roles of that user

  public constructor() {
  }

  public static create(token: string): CurrentUser {

    const result: CurrentUser = new CurrentUser();
    result.token = token;

    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    //const o: any = JSON.parse(decodedToken.sub);
    const o: any = decodedToken.sub;

    if (!('user_id' in o)) { throw new Error('token must contain user id'); }
    result.userId = o['user_id'];

    if (!('mandant_id' in o)) { throw new Error('token must contain mandant id'); }
    result.mandant_id = o['mandant_id'];

    if (!('username' in o)) { throw new Error('token must contain user name'); }
    result.username = o['username'];

    if (!('roles' in o)) {throw new Error('token must contain roles'); }
    result.roles = o['roles'];

    if (!('privileges' in o)) {throw new Error('token must contain privileges'); }
    result.privileges = o['privileges'];

    if (!('mandant_title' in o)) { throw new Error('token must contain mandant title'); }
    result.mandant_title = o['mandant_title'];

    return result;
  }

  public hasRole(role: string): boolean {
    return (this.roles != null) && (this.roles.includes(role));
  }

  public hasPrivilege(privileg: string): boolean {
      return (this.privileges != null) && (this.privileges.includes(privileg));
  }
}
