import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MeasurementService} from '../../../services/measurement.service';
import {CommentService} from '../../../services/comment.service';
import {Subscription} from 'rxjs';
import 'rxjs-compat/add/observable/of';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Comment} from '../../../entities/Comment';
import {AuthService} from '../../../services/auth.service';
import {CommentRead} from '../../../entities/MeasurementDetailsSummary';

@Component({
  selector: 'app-measurement-comment',
  templateUrl: './measurement-comment.component.html',
  styleUrls: ['./measurement-comment.component.scss']
})
export class MeasurementCommentComponent implements OnInit {

    measurementId: string;
    username: string;
    comments: Array<CommentRead>;
    commentForm: FormGroup;

    subs: Array<Subscription> = [];

  constructor(
      private route: ActivatedRoute,
      private measurementService: MeasurementService,
      private commentService: CommentService,
      private fb: FormBuilder,
      private authService: AuthService
  ) {}

    ngOnInit() {
        this.commentForm = this.fb.group({
            content: [''],
            username: [''],
            measurement_id: ['']
        });

        const sub: Subscription = this.route.params
            .switchMap(params => {
                const id: string = params['id']; // measurement identifier is a string !
                this.measurementId = id;
                this.username = this.authService.getCurrentUser().username;
                return this.measurementService.getMeasurementDetails(id);
            })
            .subscribe(summary => {
                this.comments = summary.comments;
                this.commentForm.patchValue({
                    username: this.username,
                    measurement_id: this.measurementId
                });
            });
        this.subs.push(sub);
    }

    saveComment(c: Comment, isValid: boolean) {
        if (isValid) {
            this.subs.push(
                this.commentService.saveComment(c).subscribe(
                    () => {
                        const readComment: CommentRead = new CommentRead();
                        readComment.content = c.content;
                        readComment.creationDate = 'gerade eben';
                        readComment.nick = c.username;
                        this.comments.push(readComment);
                    }
                )
            );
        }
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
        this.subs = [];
    }
}
