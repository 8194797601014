import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {NotificationService} from '../../services/notification.service';
import {Subscription} from 'rxjs';
import {NotificationMessage} from '../../entities/NotificationMessage';
import {NotificationSeverityEnum} from '../../enums/NotificationSeverityEnum';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

  private sub: Subscription;
  notifications: Array<NotificationMessage>;

  constructor(
    private notificationService: NotificationService,
    private cdref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.sub = this.notificationService.messages$.subscribe((items) => {
      this.notifications = items;
      this.cdref.detectChanges();
    });
  }

  /**
   * map message severity to bootstrap class for alert coloring
   *
   * severity | bootstrap
   * --------------------
   * success  | success (green)
   * warn     | warning (yellow)
   * info     | info (some blue)
   * error    | danger (red)
   *
   * default: secondary (light grey)
   */
  public getBCTyp(severity: NotificationSeverityEnum) {
    switch (severity) {
      case NotificationSeverityEnum.ERROR:
        return 'alert-danger';
      case NotificationSeverityEnum.INFO:
        return 'alert-info';
      case NotificationSeverityEnum.SUCCESS:
        return 'alert-success';
      case NotificationSeverityEnum.WARN:
        return 'alert-warning';
      default: return 'alert-secondary';
    }
  }

  disposeMessage(id: number) {
    this.notificationService.disposeMessage(id);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.sub = null;
  }

}
