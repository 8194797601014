import { BrowserModule } from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DataTablesModule } from 'angular-datatables';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BackendInterceptor} from './utilities/BackendInterceptor';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { LoginComponent } from './components/login/login.component';
import { NavComponent } from './components/nav/nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { AuthService } from './services/auth.service';
import {AuthGuard} from './guards/AuthGuard';
import { NotificationComponent } from './components/notification/notification.component';
import {NotificationService} from './services/notification.service';
import {GlobalErrorHandler} from './utilities/GlobalErrorHandler';
import { PatientListComponent } from './components/patient/patient-list/patient-list.component';
import { PatientDetailMenueComponent } from './components/patient/patient-detail-menue/patient-detail-menue.component';
import { PatientDetailDataComponent } from './components/patient/patient-detail-data/patient-detail-data.component';
import { PatientDetailMeasurementsComponent } from './components/patient/patient-detail-measurements/patient-detail-measurements.component';
import { UploadMeasurementComponent } from './components/utils/upload-measurement/upload-measurement.component';
import { MeasurementComponent } from './components/measurement/measurement.component';
import { MandantListComponent } from './components/mandanten/mandant-list/mandant-list.component';
import { MandantDetailComponent } from './components/mandanten/mandant-detail/mandant-detail.component';
import { UserListComponent } from './components/users/user-list/user-list.component';
import { UserDetailComponent } from './components/users/user-detail/user-detail.component';
import { YesNoDialogComponent } from './components/modals/yes-no-dialog/yes-no-dialog.component';
import { PatientEditComponent } from './components/patient/patient-edit/patient-edit.component';
import { PatientDetailUsbstickComponent } from './components/patient/patient-detail-usbstick/patient-detail-usbstick.component';
import { HasRoleDirective } from './directives/has-role.directive';
import { HasPrivilegeDirective } from './directives/has-privilege.directive';
import { MeasurementDetailRangeComponent } from './components/measurement/measurement-detail-range/measurement-detail-range.component';
import { MeasurementCommentComponent } from './components/measurement/measurement-comment/measurement-comment.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { OperatorListComponent } from './components/operators/operator-list/operator-list.component';
import { OperatorDetailComponent } from './components/operators/operator-detail/operator-detail.component';
import { DisplayImagesOneSideComponent } from './components/measurement/display-images-one-side/display-images-one-side.component';
import { PatientDetailAnamnesenComponent } from './components/patient/patient-detail-anamnesen/patient-detail-anamnesen.component';
import { PatientDetailDiagnosenComponent } from './components/patient/patient-detail-diagnosen/patient-detail-diagnosen.component';
import { FeatureComponent } from './components/feature/feature.component';
import { ConfigComponent } from './components/config-param/config-param.component';
import { ValidationComponent } from './components/measurement/validation/validation.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavComponent,
    NotificationComponent,
    PatientListComponent,
    PatientDetailMenueComponent,
    PatientDetailDataComponent,
    PatientDetailMeasurementsComponent,
    PatientDetailDiagnosenComponent,
    PatientDetailAnamnesenComponent,
    UploadMeasurementComponent,
    MeasurementComponent,
    MandantListComponent,
    MandantDetailComponent,
    UserListComponent,
    UserDetailComponent,
    YesNoDialogComponent,
    PatientEditComponent,
    PatientDetailUsbstickComponent,
    HasRoleDirective,
    HasPrivilegeDirective,
    MeasurementDetailRangeComponent,
    MeasurementCommentComponent,
    DashboardComponent,
    OperatorListComponent,
    OperatorDetailComponent,
    DisplayImagesOneSideComponent,
    FooterComponent,
    FeatureComponent,
    ConfigComponent,
    ValidationComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    DataTablesModule,
    HttpClientModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    NotificationService,
    { provide: HTTP_INTERCEPTORS, useClass: BackendInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    library.add(faEdit);
  }
}
