import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  returnUrl: string;
  loggedOut = false;
  errorMessage: String;
  subs: Array<Subscription> = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,

  ) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    // redirect, if we have already a user
    if (this.authService.hasCurrentUser()) {this.router.navigate([this.returnUrl]);}

    // set logout message, if url has query param /login?logout
    this.loggedOut = ('logout' in this.route.snapshot.queryParams);

    // set subscriptions to login status
    this.subs.push(
      this.authService.onLoginSuccess.subscribe( () => { this.router.navigate([this.returnUrl]); })
    );

    this.subs.push(
      this.authService.onLoginFailed.subscribe(() => this.errorMessage = 'Unable to login, try again.')
    );
  }

  public login(username: string, password: string): void {
    this.authService.login(username, password);
  }

  ngOnDestroy() {
    this.subs.forEach(subs => subs.unsubscribe());

    this.subs = [];
  }

}
