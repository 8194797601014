import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
declare var $: any; // jquery

@Component({
  selector: 'app-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.scss']
})
export class YesNoDialogComponent implements OnInit, OnDestroy {

  public modalViewId: string;

  @Input() title: string;
  @Input() text: string;

  // triggers the modal to show up
  private _show = false;
  @Input('show')
  set show(value: boolean) {
    this._show = value;
    if (value === true) {
      $('#' + this.modalViewId).modal('show');
    }
  }

  private _id: any = null;
  @Input ('modalId')
  set id(value: any) {
    this._id = value;
  }

  // user said yes, returns the given modalId to be consumable in parent container
  @Output() confirm: EventEmitter<any> = new EventEmitter<any>();

  // user said no
  @Output() deny: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    const rand = Math.trunc(Math.random() * 10000000);
    this.modalViewId = 'yesNoModal' + String(rand);
  }

  sayYes(): void {
    $('#' + this.modalViewId).modal('hide');
    this.confirm.next(this._id); // return the input id to know how to process the yes answer
    this._show = false;
  }

  sayNo(): void {
    $('#' + this.modalViewId).modal('hide');
    this.deny.next();
    this._show = false;
  }

  ngOnDestroy(): void {
    if (this._show) { this.sayNo(); }
  }

}
