import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractDataTableComponent } from 'src/app/components/generics/AbstractDataTableComponent';
import { FeatureToggle } from 'src/app/entities/FeatureToggle';
import { FeatureToggleService } from 'src/app/services/feature_toggles_service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.scss']
})
export class FeatureComponent extends AbstractDataTableComponent implements OnInit, OnDestroy {

  subs: Subscription[] = [];
  FeatureList: FeatureToggle[] = [];

  constructor(private featureToggleService: FeatureToggleService) {
    super('feature list');
  }


  ngOnInit() {
    this.subs.push(this.featureToggleService.getFeatures().subscribe(list => {
      this.FeatureList = list;
      this.rerender();
    }));

  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
  }

  setFeatureStatus(id: Number, status: boolean): void {
    this.subs.push(this.featureToggleService.setFeatureStatus(id, status).subscribe((data) => {
      let feature = this.FeatureList.find(f => f.id == data.id);
      if (feature) {
        feature.enabled = data.enabled;
      }
    }));
  }
}
