import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UploadMeasurementService {

  constructor(private httpClient: HttpClient) { }

  // TODO: add an angular test for this upload (using mocked server)
  public postFile(fileToUpload: File): Observable<boolean> {
    const endpoint = '/api/measurements';
    const file_key = 'measurement_file';
    const formData: FormData = new FormData();
    formData.append(file_key, fileToUpload, fileToUpload.name); //
    return this.httpClient
        .post(endpoint, formData, {headers : {'skip' : 'Content-Type'}}) // do not add application-json in http interceptor
        .map(() => true);
  }
}
