import {Component, OnDestroy, OnInit} from '@angular/core';
import {PatientService} from '../../../services/patient.service';
import {Patient} from '../../../entities/Patient';
import {AbstractDataTableComponent} from '../../generics/AbstractDataTableComponent';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../services/auth.service';
import {CurrentUser} from '../../../entities/CurrentUser';
import * as moment from 'moment';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss']
})
export class PatientListComponent extends AbstractDataTableComponent implements OnInit, OnDestroy {

  patienten: Array<Patient> = [];
  sub: Subscription;
  currentUser: CurrentUser;

  constructor(
    private authService: AuthService,
    private patientService: PatientService
  ) {
    super('patient list');
  }

  ngOnInit() {
    // input format is 'DD.MM.YYYY HH:mm' from BackEnd
    // DateTables uses Date.parse(xx) for sorting
    // Date.parse(xx) cant parse this specific format, thus it will fall back to string sorting
    // to correctly sort date-de format, we must parse date string via moment manually
    // see: https://stackoverflow.com/a/55000726
    this.dtOptions.columnDefs = [
      {
        targets: [3],
        type: 'date-time',
        render: function(data, type) {
          if (data === 'Invalid Date' || data === 'undefined' || data === null) { return null; }
          console.log('render colDef', data, type);
          switch (type) {
            case 'sort' : return moment(data, 'DD.MM.YYYY HH:mm').format('YYYY/MM/DD HH:mm'); break;
            default: return data;
          }
        }
      }
    ];

    this.currentUser = this.authService.getCurrentUser();
    this.fetchData();
  }

  fetchData(): void {
    if (this.sub != null) { this.sub.unsubscribe(); }
    this.sub = null;
    this.sub = this.patientService.getPatientList().subscribe(patients => {
      this.patienten = patients;
      this.rerender();
    });
  }


  ngOnDestroy(): void {
    // this.dtOptions.columnDefs = [];
    if (this.sub != null) { this.sub.unsubscribe(); }
  }
}
