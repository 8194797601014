import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {NotificationService} from '../services/notification.service';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {HttpErrorResponse} from '@angular/common/http';
import {BackendErrorResponseDto} from '../entities/BackendErrorResponseDto';

/**
 * Catch Error globally and push message to notification service
 *
 * in case of HttpErrorResponse, we expect a structured error message,
 * see backend {@code de.davitec.javaangularbase.exceptions.handler.ErrorReponseDto}
 *
 * in special case of a 401, go back to login page
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) {}

  handleError(error: any): void {
    const notificationService = this.injector.get(NotificationService);
    const router: Router = this.injector.get(Router);
    const authService: AuthService = this.injector.get(AuthService);

    if (error.status === 401) {
      notificationService.error('redirect to login page due to 401', 'Invalid Auth');
      authService.logout();
      return;
    }

    // else
    console.warn('globally catched error', error);
    if (!(error instanceof HttpErrorResponse)) {
      notificationService.error(error.message ? error.message : error.toString(), 'An error occured');
      return;
    }

    // else
    const err: HttpErrorResponse = error;
    try {
      const dto: BackendErrorResponseDto = Object.assign(new BackendErrorResponseDto(), err.error);
      notificationService.error(dto.message, dto.status + ' ' + dto.error);
    } catch (parseError) {
      notificationService.error('unable to resolvable http response error message, please refer console log', 'Http Error');
    }
    /**
     *   you might also push to backend error log or
     *   re-throw error here, i.e
     *     throw Error(error); //re-throw, but maybe looping issues
     */
  }

}
