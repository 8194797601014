import { Component, OnInit } from '@angular/core';
import {ConfigParamsService} from 'src/app/services/config_params_service';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
  selector: 'app-config',
  templateUrl: './config-param.component.html',
  styleUrls: ['./config-param.component.scss']
})
export class ConfigComponent implements OnInit {

  configParams: Array<any> = [];
  editConfigParamId: number = -1;

  constructor(
    private configParamsService: ConfigParamsService,
    private notificationService: NotificationService,
  ) {
    
   }

  ngOnInit(): void {
    this.configParamsService.list().subscribe(data => {
      this.configParams = data;
    });
  }

  activateEditMode(configParamId: number) {
    console.log('activate edit mode for config param id: ' + configParamId);
    this.editConfigParamId = configParamId;
  }

  cancelEdit() {
    this.editConfigParamId = -1;
  }

  updateConfigParam(id: number, newValue: string): void {
    this.configParamsService.updateValue(id, newValue).subscribe(
      response => {
        //console.log('Config param updated successfully', response);
        this.notificationService.success('Config Param erfolgreich gespeichert');
        this.editConfigParamId = -1; // Exit edit mode
      },
      error => {
        //console.error('Error updating config param', error);
        this.notificationService.error('Fehler beim Speichen des Config Params: ' + error.error.message);
      }
    );
    }
}